// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-status-js": () => import("./../../../src/pages/status.js" /* webpackChunkName: "component---src-pages-status-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-tiktok-js": () => import("./../../../src/pages/tiktok.js" /* webpackChunkName: "component---src-pages-tiktok-js" */),
  "component---src-templates-tiktok-video-js": () => import("./../../../src/templates/tiktok-video.js" /* webpackChunkName: "component---src-templates-tiktok-video-js" */),
  "component---src-templates-works-js": () => import("./../../../src/templates/works.js" /* webpackChunkName: "component---src-templates-works-js" */)
}

